<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.sea_table") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.sea_table_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <TidesChart />
    </div>
  </div>
</template>

<script>
import TidesChart from "./Hydro/TidesChart";

export default {
  components: {
    TidesChart,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
