<template>
  <Tides />
</template>

<script>
import Tides from "@/components/Configurations/Tides";

export default {
  components: {
    Tides,
  },
};
</script>
