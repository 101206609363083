<template>
  <div class="chart-container">
    <v-progress-linear
      height="10"
      striped
      color="lime"
      indeterminate
      v-show="loading"
    ></v-progress-linear>
    <v-chart :option="option" autoresize />
  </div>
</template>

<script>
import VChart from "vue-echarts";
import * as echarts from "echarts";

export default {
  components: {
    VChart,
  },
  data() {
    return {
      loading: false,
      option: {},
    };
  },
  mounted() {
    this.loadTides();
  },
  methods: {
    loadTides() {
      this.loading = true;
      let data = [];

      this.$store.dispatch("tides/GET_PREDICTIONS").then((predictions) => {
        predictions.forEach((element) => {
          data.push([element.datetime, element.height]);
        });

        let markPoints = {
          data: [],
        };

        if (data && data.length > 0) {
          //calc all local max
          data
            .filter((el, index) => {
              return (index - 1 >= 0) & (index + 1 < data.length)
                ? el[1] > data[index - 1][1] && el[1] > data[index + 1][1]
                : false;
            })
            .forEach((element) => {
              markPoints.data.push({
                coord: element,
                label: {
                  formatter: element[1],
                },
                itemStyle: {
                  color: "#c33d41",
                },
              });
            });

          //calc all local min
          data
            .filter((el, index) => {
              return (index - 1 >= 0) & (index + 1 < data.length)
                ? el[1] < data[index - 1][1] && el[1] < data[index + 1][1]
                : false;
            })
            .forEach((element) => {
              markPoints.data.push({
                coord: element,
                label: {
                  formatter: element[1],
                  color: "white",
                },
                itemStyle: {
                  color: "#3997c8",
                },
              });
            });
        }

        this.loading = false;
        this.option = {
          title: {
            text: "",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: [],
          },
          grid: {
            left: "6%",
            right: "6%",
            bottom: "10%",
            containLabel: true,
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
              formatter: function (value) {
                return echarts.format.formatTime("dd/MM hhH", value);
              },
            },
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, "30%"],
            axisLabel: {
              formatter: "{value} m",
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
          },
          dataZoom: [
            {
              type: "slider",
              height: 30,
              start: 0,
              bottom: 20,
            },
          ],
          series: [
            {
              name: this.$t("configurations.tide_prevision"),
              type: "line",
              smooth: true,
              symbol: "none",
              itemStyle: {
                color: "#009cfc",
              },
              markPoint: markPoints,
              markLine: {
                data: [
                  {
                    symbol: "circle",
                    label: {
                      position: "end",
                      formatter: this.$t("global.average"),
                      color: "black",
                    },
                    type: "average",
                    lineStyle: {
                      color: "black",
                    },
                  },
                  {
                    symbol: "circle",
                    label: {
                      position: "end",
                      formatter: this.$t("global.min"),
                      color: "#3997c8",
                    },
                    type: "min",
                    lineStyle: {
                      color: "#3997c8",
                    },
                  },
                  {
                    symbol: "circle",
                    label: {
                      position: "end",
                      formatter: this.$t("global.max"),
                      color: "#c33d41",
                    },
                    type: "max",
                    lineStyle: {
                      color: "#c33d41",
                    },
                  },
                ],
              },
              areaStyle: {
                color: "rgba(0, 156, 252, 0.2)",
              },
              data: data,
            },
          ],
        };
      });
    },
  },
};
</script>

<style>
.echarts {
  width: 100%;
  height: calc(100vh - 160px);
  background-color: #f5f6f9;
}

.chart-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 160px);
}
</style>
